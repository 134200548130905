import { FC, useEffect, useState } from "react";
import LoadingComponent from "./LoadingComponent";
import styles from './MetObs.module.css';
import { PeriodOption } from "./PeriodOption";

type Station = {
  id: string,
  stationId: string,
  name: string,
  lat: number,
  lon: number,
  kmFromClientLocation: number,
  isSynop: boolean,
};

type MetObs = {
  precipitation: number,
  temperature: number,
  humidity: number,
  windSpeed: number,
  observed: string,
};

const MetObsComponent: FC = () => {

  const [state, setState] = useState({
    loading: true,
    currentPeriodOption: PeriodOption.WEEK,
    metObs: null as unknown as MetObs,
    station: null as unknown as Station,
    stations: [] as Station[],
  });

  const done = (stations: Station[], metObs: MetObs) => {
    setState(prevState => {
      return {
        loading: false,
        currentPeriodOption: prevState.currentPeriodOption,
        metObs: metObs,
        station: stations[0],
        stations: stations,
      }
    });
  };

  const fetchMetObs = async (stationId: string, option: PeriodOption = state.currentPeriodOption) => {

    const metObsResponse = await fetch(`api/metobs/${stationId}/${option}`)
    // const metObsResponse = await fetch(`http://localhost:5001/api/metobs/${stationId}/${option}`)
    const metObs = await metObsResponse.json() as MetObs;
    metObs.precipitation = Math.round(metObs.precipitation * 10) / 10;

    if (metObs.observed)
      metObs.observed = (new Date(metObs.observed)).toLocaleString();

    return metObs;
  }

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async success => {

        const latlonUrl = `/${success.coords.latitude}/${success.coords.longitude}`;

        const stationsResponse = await fetch(`api/station${latlonUrl}`);
        // const stationsResponse = await fetch(`http://localhost:5001/api/station${latlonUrl}`);
        const stations = await stationsResponse.json() as Station[];
        const station = stations[0];

        done(stations, await fetchMetObs(station.stationId));

      }, error => {
      }, { enableHighAccuracy: true });
    }
  }, []);

  const changeOption = async (newOption: PeriodOption) => {

    setState(prevState => {
      return {
        ...prevState,
        currentPeriodOption: newOption,
        loading: true,
      }
    });

    const metObs = await fetchMetObs(state.station.stationId, newOption);

    setState(prevState => {
      return {
        ...prevState,
        loading: false,
        metObs: metObs,
      }
    });
  };

  const renderOptionButton = (text: string, option: PeriodOption) => {
    if (option === state.currentPeriodOption) {
      return (
        <button className='selected' onClick={() => changeOption(option)}>{text}</button>
      );
    }
    else
      return (
        <button onClick={() => changeOption(option)}>{text}</button>
      );
  };

  const changeStation = async (stationId: string) => {

    const newStation = state.stations.find(s => s.stationId == stationId) || state.stations[0];

    setState(prevState => {
      return {
        ...prevState,
        station: newStation,
        loading: true,
      }
    });

    const metObs = await fetchMetObs(stationId);

    setState(prevState => {
      return {
        ...prevState,
        loading: false,
        metObs: metObs,
      }
    });
  };

  return (
    <div>
      <div className={`${styles.pane} ${styles.station_pane}`}>
        <p>Målestation</p>
        <select onChange={e => changeStation(e.target.value)} disabled={state.loading}>
          {
            state.stations.map(station => {
              return (
                <option key={station.id} value={station.stationId}>{station.name} ({station.kmFromClientLocation} km){station.isSynop ? ' (_SYNOP_)' : ''}</option>
              );
            })
          }
        </select>
      </div>
      <div className={styles.pane}>
        <div className={styles.value}>
          {
            state.loading ?
              <LoadingComponent />
              :
              <div>
                <span>{state.metObs?.precipitation}</span>
                <span className={styles.unit}>mm</span>
              </div>
          }
        </div>
        <div className={styles.interval_setting}>
          {renderOptionButton('1h', PeriodOption.HOUR)}
          {renderOptionButton('24h', PeriodOption.DAY)}
          {renderOptionButton('1w', PeriodOption.WEEK)}
          {renderOptionButton('2w', PeriodOption.TWOWEEKS)}
          {renderOptionButton('1m', PeriodOption.MONTH)}
        </div>
      </div>
      <div className={styles.pane}>
        {
          state.loading ?
            <LoadingComponent />
            :
            <div>
              <p>Øvrige aktuelle målinger</p>
              <ul className={styles.metobs}>
                <li>Temperatur: {state.metObs?.temperature} drg C</li>
                <li>Luftfugtighed: {state.metObs?.humidity}%</li>
                <li>Vind: {state.metObs?.windSpeed} m/s</li>
              </ul>
              <p>Målt: <span className={styles.measured}>{state.metObs?.observed}</span></p>
            </div>
        }
      </div>
      {/* <div className={styles.pane}>
                <p>Info / om ...</p>
            </div> */}
    </div>
  );
};

export default MetObsComponent;
