import styles from './LoadingComponent.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'

const LoadingComponent = () => {
    return (
        <p className={styles.loading}>
            <FontAwesomeIcon icon={faCircleNotch}  spin />
        </p>
    );
};

export default LoadingComponent;