import './App.css';
import MetObsComponent from './features/metobs/MetObsComponent';

function App() {
  return (
    <div className="App">
      <MetObsComponent></MetObsComponent>
    </div>
  );
}

export default App;
